<template>
  <div class="mb-5 mb-xl-10 tabs-container" style="color: #ffffff">
    <div class="card-body pt-9 pb-0">
      <div v-if="contactData.id" class="d-flex flex-wrap flex-sm-nowrap mb-3">
        <!--begin: Pic-->
        <!--end::Pic-->

        <!--begin::Info-->
        <div class="flex-grow-1">
          <!--begin::Title-->
          <div
            class="d-flex justify-content-between align-items-start flex-wrap mb-2"
          >
            <!--begin::User-->
            <div class="d-flex flex-column">
              <!--begin::Name-->
              <div class="d-flex align-items-center mb-2">
                <a
                  href="javascript:void(0)"
                  class="fs-2 fw-bolder me-1"
                  style="color: #ffffff"
                >
                  {{ contactData.name }} {{ contactData.last_name }}
                </a>
                <a href="javascript:void(0)">
                  <span class="svg-icon svg-icon-1 svg-icon-primary">
                    <inline-svg
                      src="../../../media/icons/duotone/Design/Verified.svg"
                    />
                  </span>
                </a>
              </div>
              <!--end::Name-->

              <!--begin::Info-->
              <!--end::Info-->
            </div>
            <!--end::User-->

            <!--begin::Actions-->
            <div class="d-flex my-4"></div>
            <!--end::Actions-->
          </div>
          <!--end::Title-->

          <!--begin::Stats-->
          <div class="d-flex flex-wrap flex-stack">
            <!--begin::Wrapper-->
            <div class="d-flex flex-column flex-grow-1 pe-8">
              <!--begin::Stats-->
              <div class="d-flex flex-wrap">
                <!--begin::Stat-->
                <div
                  class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                >
                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <span class="svg-icon svg-icon-success me-2"> </span>
                    <div class="fs-2 fw-bolder" data-kt-countup="true">
                      {{
                        contactData.entities ? contactData.entities.length : 0
                      }}
                    </div>
                  </div>
                  <!--end::Number-->

                  <!--begin::Label-->
                  <div class="fw-bold fs-6 text-gray-400">
                    {{ $t("entities") }}
                  </div>
                  <!--end::Label-->
                </div>
                <!--end::Stat-->

                <!--begin::Stat-->
                <div
                  class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                >
                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <span class="svg-icon svg-icon-3 svg-icon-danger me-2">
                    </span>
                    <div class="fs-2 fw-bolder" data-kt-countup="true">
                      {{
                        contactData.communications
                          ? contactData.communications.length
                          : 0
                      }}
                    </div>
                  </div>
                  <!--end::Number-->

                  <!--begin::Label-->
                  <div class="fw-bold fs-6 text-gray-400">
                    {{ $t("icommunications") }}
                  </div>
                  <!--end::Label-->
                </div>
                <!--end::Stat-->
              </div>
              <!--end::Stats-->
            </div>
            <!--end::Wrapper-->

            <!--begin::Progress-->
            <!--end::Progress-->
          </div>
          <!--end::Stats-->
        </div>
        <!--end::Info-->
      </div>
      <div class="d-flex overflow-auto h-55px">
        <ul
          class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
        >
          <!--begin::Nav item-->
          <li class="nav-item">
            <router-link
              :to="`/contact/${contactData.id}/general`"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              {{ $t("General") }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import store from "@/store";

export default {
  name: "Head",
  setup() {
    const contactData = computed(() => store.getters.ContactData);
    return {
      contactData,
    };
  },
};
</script>

<style scoped></style>
