<template>
  <Head :contact="contactData" />
  <router-view></router-view>
</template>

<script>
import Head from "./components/Head.vue";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default {
  name: "Index",
  components: {
    Head,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const contactData = ref({});
    const getContact = async () => {
      contactData.value = await ApiService.get(
        `/api/contacts/${route.params.id}`
      ).then(function (response) {
        contactData.value = response.data;
        store.commit("setContact", response.data);
        store.commit("setEntities", response.data.entities);
      });
    };
    const generateContact = async () => {
      store.commit("setContact", {});
      store.commit("setEntities", []);
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs(
        route.params.id === "add" ? "contactCreate" : "contactUpdate",
        [
          { route: "/entities", label: "entities" },
          { route: "/contacts", label: "Contacts" },
        ]
      );
      if (route.params.id === "add") {
        generateContact();
      } else {
        getContact();
      }
    });

    return {
      contactData,
      getContact,
    };
  },
};
</script>

<style scoped></style>
